
#editForm
{
	width:100%;
}


::-moz-placeholder {
	color: #666;
	font-weight: 300;
	opacity: 1;
}

::-webkit-input-placeholder {
	color: #666;
	font-weight: 300;
}

.grid .col2 {
	width: 100%;
	padding: 0 10px 0 0;
}
.grid .col2.first {
	float: left;
}
.grid .col2.last {
	float: right;
}

.grid .col3 {
	width: 32%;
	float: left;
	margin-right: 11px;
}
.grid .col3.first {
	margin-left: 0;
	float: left;
}
.grid .col3.last {
	margin-right: 0;
	float: right;
}

.section1 {
  animation-name: fadeIn;
  animation-duration: 3s;
	background: #fff;
	position: relative;
  border-radius: 2px;
  width: 100%;
  margin:auto;
}
.section1 div .row:first-child {
	padding: 25px;
}


@keyframes fadeIn{
  from { opacity: 0; }
  to   { opacity: 1; }

  
}
.section1 .col2.first img {
	border-radius: 50%;
	margin: 0 20px;
  width: 120px;
  height:110px;
	border:2px solid #f1f1f1;
	padding: 2px;
	float: left;
}
.section1 .col2.first {
	line-height: 25px;
	position: relative;
}
.section1 .col2.first h1 {
    font-weight: normal;
    margin-bottom: 10px;
	margin-top: 15px;
	text-transform: capitalize;
}
.section1 .col2.first p {
	font-size: 14px;
}
.section1 .col2.first span {
	position: absolute;
    right: 15px;
    top: 16px;
    background: #6AAFEA;
    padding: 5px 11px;
    font-size: 12px;
    line-height: 1;
    border-radius: 2px;
    color: #fff;
  cursor:pointer;
}

.section1 .col2.last {
	padding: 8px 0;
}
.section1 .col2.last .col3 span {
    color: #a2a2a2;
    font-size: 14px;
}
.section1 .col2.last .col3 h1 {
    color: #FB4C22;
}

.section1 .col2.last .col3 {
    text-align: center;
    line-height: 30px;
    border-right: 1px solid #ccc;
}
.section1 .col2.last .col3.last {
	border-right: 0;
}

.hidDiv
{
  margin:20px;
}
.row2tab li {
    list-style: none;
    float: left;
    width: 33%;
    padding: 15px;
    font-size: 14px;
    text-align: center;
    cursor:pointer;
    color: #555;
    border-bottom: 2px solid #f1f1f1;
    margin-bottom: 10px;
}

.row2tab li i {
        margin-right: 3px;
    font-size: 14px;
}


@media only screen and (max-width: 1300px) {

	.section1 .grid .col3 {
		margin-right: 2%;
	}
	.section1 .col2.last .col3.last {
		margin-right: 0;
	}
}

@media only screen and (max-width: 1060px) {
	.section1 .col2 {
		width: 100%;
		border-right:0 !important;
		padding: 0;
	}
}

@media only screen and (max-width: 660px) {

	.row2tab li {
		width: 50%;
		text-align: left;
	}
	.section1 .col2.first {
		text-align: center;
	}
	.section1 .col2.first img {
		display: inline-block;
		float: none;
	}
	.section1 .col2.first span {
		position: relative;
		right: 0;
	}
  .section1 .col2.last {
    margin-top:25px;
  }
}
@media only screen and (max-width: 450px) {

  .row2tab li {
		width: 100%;
		text-align: left;
	}
	.section1 .col2.last .col3 span {
		font-size: 10px;
	}
	.section1 .col2.last .col3 h1 {
		font-size: 18px;
	}
	
}