@import url('https://fonts.googleapis.com/css2?family=Merienda+One&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Work+Sans:wght@400&display=swap');


.App-logo {
  height: 40vmin;
  pointer-events: none;
}



@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.post-list {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.post{
  margin: 0 auto;
  max-width: 640px;
  /*box-shadow: 0 6px 12px rgba(0,0,0,.175);*/
}

.post-list .post {
  margin: 0 auto 60px auto;
}

.post.panel .panel-body {
  padding: 0;
}

.post.panel .panel-body figcaption.lead {
  padding: 15px 15px 0 15px;
}

.campaign-img {
  object-fit: cover; /* Do not scale the image */
  object-position: center; /* Center the image within the element */
  width: 100%;
  max-height: 350px;
  margin-bottom: 1rem;
}

.login-btn
{
  background:    #43dc43;
  border-radius: 4px;
  padding:       13px 30px;
  color:         #ffffff;
  display:       inline-block;
  font:          normal bold 22px/1 "Open Sans", sans-serif;
  text-align:    center;
}

.signup-btn
{background:    #cc0000;
  border-radius: 4px;
  padding:       13px 30px;
  color:         #ffffff;
  display:       inline-block;
  font:          normal bold 22px/1 "Open Sans", sans-serif;
  text-align:    center;

}