
.poster {
    margin:30px;
  border-radius: 10px;
  overflow: hidden;
  width: 375px;
  box-shadow: 1px 0 10px 0 rgba(0, 0, 0, 0.2);}

  #posterimg {
    height: 200px;
    background-position: center;
    background-size: cover;
    width: 375px;
  }
#posterinfo {
    background: #fff;
  }
  #text {
    
    font-size: .9em;
    color: #999;
    font-weight: 300;
  }


#as {
  display: block;
  width: 250px;
  background: #27ae60;
  text-align: center;
  margin: 60px ;
  height: 50px;
  line-height: 50px;
  border-radius: 25px;
  color: #fff;
  text-transform: uppercase;
  letter-spacing: .15em;
  text-decoration: none;
  font-size: .9em;
  font-weight: 300;
  background: linear-gradient(90deg, #2ecc71, #27ae60);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.10), 0 1px 2px rgba(0, 0, 0, 0.18);
  transition: 0.3s;
  
}
#as :hover {
    box-shadow: 0 8px 18px rgba(0, 0, 0, 0.10), 0 8px 8px rgba(0, 0, 0, 0.18);
  }